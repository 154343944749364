<script setup lang="ts">
const { t } = useT();
const { open } = useNlcModals();
const { data: appInitData } = useAppInitData();
const { data, firstItemData, firstItemTimer } = useScretchCardData({
	immediate: !appInitData.value?.isGuest && appInitData.value?.magicBox?.isActive
});

const CHESTS = [1, 2, 3] as const;

const buttonName = computed(() => {
	if (appInitData.value?.isGuest) {
		return t("Register");
	}

	if (firstItemData.value?.progress?.points === firstItemData.value?.progress?.pointsComplete) {
		return t("cosmicBoxes.promotion.button.name1");
	}

	if (firstItemData.value.timer) {
		return firstItemTimer.value;
	}

	return t("cosmicBoxes.promotion.button.name2");
});

const handleClick = () => {
	if (firstItemData.value?.progress?.points === firstItemData.value?.progress?.pointsComplete) {
		open("LazyOModalCosmicBoxesSelection");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<MPromotionWrapper
		type="scratchCards"
		:title="t('cosmicBoxes.promotion.title')"
		image="/nuxt-img/cosmic-boxes/promo-card.jpg"
		:buttonName="buttonName"
		:buttonDisabled="!!firstItemData?.timer"
		buttonInfo
		@click-info="open('LazyOModalCosmicBoxesHowItWorks')"
		@click-card="handleClick"
	>
		<template #top>
			<div class="box-chests">
				<MCosmicBoxesChest v-for="(chest, index) in CHESTS" :key="index" :variant="chest" />
			</div>
		</template>

		<AText v-if="firstItemData" class="description" :size="16" :modifiers="['linght']">
			{{ t("cosmicBoxes.promotion.progress") }}
		</AText>

		<MCosmicBoxesProgress
			v-if="firstItemData"
			:value="firstItemData?.progress?.pointsComplete"
			:maxValue="firstItemData?.progress?.points"
		/>

		<MCounterBadgeGroup v-if="data?.finishedAt" :timestamp="data?.finishedAt ?? ''" :badgeText="t('Limited Offer')" />

		<AText v-if="firstItemData.timer" class="before-btn" :size="16">
			{{ t("cosmicBoxes.promot.button.beforeTxt") }}
		</AText>
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.description {
	margin-bottom: 4px;
}

.box-counter {
	margin-top: 24px;
}

.box-chests {
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: space-between;
	width: 201px;
	height: 157px;
	margin: -6px auto -10px;

	.chest {
		position: absolute;
		width: 126px;
		top: 6px;
	}

	.chest-1 {
		left: -56px;
	}

	.chest-2 {
		width: 166px;
		top: auto;
		bottom: 0;
		left: calc(50% - 83px);
		z-index: 2;
	}

	.chest-3 {
		right: -56px;
	}
}

.before-btn {
	display: inline-flex;
	margin: 12px 0 -12px;
}
</style>
